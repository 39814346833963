import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faUser,
  faBell,
  faSignOutAlt,
  faEnvelope,
  faMoneyBillWave,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";

const Home = () => {
  const navigate = useNavigate();
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(null);
  const [properties, setProperties] = useState([]);

  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const userName = userData.fullName;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      fetchPropertyData();
      fetchTotalEarnings();
      fetchNotifications();
    }
  }, [token, userId]);

  const fetchPropertyData = async () => {
    try {
      const response = await axios.get(
        `https://backend.inyumba.co.ke/iNyumba/admin/househunter/fetchhunterpropertyindividual/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProperties(response.data.response);
    } catch (error) {
      console.error("Error fetching property:", error);
    }
  };

  const fetchTotalEarnings = async () => {
    try {
      const response = await axios.get(
        `https://backend.inyumba.co.ke/iNyumba/admin/househunter/fetchhunterb/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTotalEarnings(response.data.response || 0);
    } catch (error) {
      console.error("Error fetching balance:", error);
      setTotalEarnings(0);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/inappnotifications/viewallnotifications",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setNotificationsCount(response.data.response.length);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      toast.error("Error fetching notifications");
    }
  };

  const handleEmailClick = () => {
    navigate("/propertyhunter/sendemail");
  };

  const handleWithdrawClick = () => {
    navigate("/propertyhunter/settlehousehunters");
  };

  const handleRecordsClick = () => {
    // toast.info("Records functionality to be implemented.");
    navigate("/propertyhunter/settlements");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-semibold">
            Property hunter - {userName}
          </h1>
          <div className="flex items-center">
            <div
              className="relative cursor-pointer"
              onClick={() => navigate("/propertyhunter/notifications")}
            >
              <FontAwesomeIcon icon={faBell} className="text-xl mr-4" />
              {notificationsCount > 0 && (
                <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                  {notificationsCount}
                </span>
              )}
            </div>
            <FontAwesomeIcon
              icon={faUser}
              className="text-xl mr-4 cursor-pointer"
              onClick={() => navigate("/propertyhunter/profile")}
            />
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className="text-xl cursor-pointer"
              onClick={() => navigate("/logout")}
            />
          </div>
        </div>

        <div className="glassmorphic-view p-4 mb-6 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-semibold mb-2">Total Earnings</h2>
          <p className="text-xl font-medium">
            {totalEarnings !== null ? `Ksh ${totalEarnings}` : "Loading..."}
          </p>
        </div>

        <div className="glassmorphic-view p-4 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-semibold mb-4">Properties</h2>
          <div className="flex flex-wrap gap-4">
            {properties.map((prop) => (
              <div
                key={prop.id}
                className="glassmorphic-property w-64 rounded-lg shadow-md overflow-hidden bg-white flex-shrink-0"
              >
                {/* Image or Placeholder Section */}
                <div className="w-full h-48 bg-gray-100 flex items-center justify-center">
                  {prop.photo ? (
                    <img
                      src={prop.photo}
                      alt={`${prop.title} image`}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full bg-white"></div>
                  )}
                </div>

                {/* Details Section */}
                <div className="p-4">
                  <h3 className="text-xl font-medium mb-2">{prop.title}</h3>
                  <p className="text-sm text-gray-600">
                    <strong>County:</strong> {prop.county}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Location:</strong> {prop.location}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Rate:</strong> {prop.percentage}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Balance:</strong> {prop.balance}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-around mt-8">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            onClick={handleEmailClick}
          >
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> Send Email of
            New Properties
          </button>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
            onClick={handleWithdrawClick}
          >
            <FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" /> Withdraw
          </button>
          <button
            className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-700"
            onClick={handleRecordsClick}
          >
            <FontAwesomeIcon icon={faList} className="mr-2" /> View Your
            Settlement Records
          </button>
        </div>

        <ToastContainer />
      </div>
    </AuthGuard>
  );
};

export default Home;
