import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom"; // Import Navigate for redirection
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify"; // Import ToastContainer
import "./style.css"; // Import Tailwind CSS

import Login from "./Auth/Login";
import OtpVerification from "./Auth/OtpVerification";
import ForgotPassword from "./Auth/ForgotPassword";
import Logout from "./Auth/Logout";
import Home from "./PropertyHunter/home";
import Profile from "./PropertyHunter/profile";
import { useEffect } from "react";
import NotificationsPage from "./PropertyHunter/notifications";
import Registration from "./Auth/Registration";
import WithdrawalsPage from "./PropertyHunter/settlehousehunters";
import SendEmail from "./PropertyHunter/sendemail";
import Settlements from "./PropertyHunter/settlements";

function App() {
  return (
    <Router>
      <Routes>
        {/* Routes accessible whether user is logged in or not */}
        <Route path="/" element={<Login />} />
        <Route path="/OtpVerification" element={<OtpVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Registration />} />

        {/* Routes accessible only when user is logged in */}

        <Route path="/propertyhunter/home" element={<Home />} />
        <Route path="/propertyhunter/profile" element={<Profile />} />
        <Route
          path="/propertyhunter/notifications"
          element={<NotificationsPage />}
        />
        <Route
          path="/propertyhunter/settlehousehunters"
          element={<WithdrawalsPage />}
        />
        <Route path="/propertyhunter/sendemail" element={<SendEmail />} />
        <Route path="/propertyhunter/settlements" element={<Settlements />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;

export function AuthGuard({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const userDataString = localStorage.getItem("user");

    try {
      // Parse the JSON string to convert it into a JavaScript object
      const userData = JSON.parse(userDataString);
      // Access the 'unique' property from the 'user' object
      const userId = userData.unique;
      if (!userId) {
         navigate("/");
      }
    } catch (error) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}

export function TokenGuard({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}
