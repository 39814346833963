import React, { useState } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SendEmail = () => {
  const [landlordName, setLandlordName] = useState("");
  const [landlordPhoneNumber, setLandlordPhoneNumber] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [loading, setLoading] = useState(false);

  // Get user data from local storage
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const userEmail = userData.email;
  const userPhone = userData.phone;
  const userName = userData.fullName;
  const token = localStorage.getItem("token");

  const propertyHunterEmail = userEmail;
  const propertyHunterUserId = userId;
  const propertyHunterPhoneNumber = userPhone;
  const propertyHunterName = userName;

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/admin/email/sendtoadminandpropertyhunter", // Replace with your actual endpoint
        {
          landlordname: landlordName,
          landlordphonenumber: landlordPhoneNumber,
          propertyname: propertyName,
          propertyhuntername: propertyHunterName,
          propertyhunterphonenumber: propertyHunterPhoneNumber,
          propertyhunteruserid: propertyHunterUserId,
          propertyhunteremail: propertyHunterEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle success
      toast.success(response.data.message); // Adjust based on your response structure
      setLandlordName("");
      setLandlordPhoneNumber("");
      setPropertyName("");
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Determine if the submit button should be enabled
  const isFormValid = landlordName && landlordPhoneNumber && propertyName;

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Email Submission Form</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="landlordName" className="block mb-1">
              Landlord Name
            </label>
            <input
              type="text"
              id="landlordName"
              value={landlordName}
              onChange={(e) => setLandlordName(e.target.value)}
              required
              className="border rounded-md p-2 w-full"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="landlordPhoneNumber" className="block mb-1">
              Landlord Phone Number
            </label>
            <input
              type="tel"
              id="landlordPhoneNumber"
              value={landlordPhoneNumber}
              onChange={(e) => setLandlordPhoneNumber(e.target.value)}
              required
              className="border rounded-md p-2 w-full"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="propertyName" className="block mb-1">
              Property Name
            </label>
            <input
              type="text"
              id="propertyName"
              value={propertyName}
              onChange={(e) => setPropertyName(e.target.value)}
              required
              className="border rounded-md p-2 w-full"
            />
          </div>

          {/* Hidden fields */}
          <input type="hidden" value={propertyHunterUserId} />
          <input type="hidden" value={propertyHunterEmail} />
          <input type="hidden" value={propertyHunterPhoneNumber} />
          <input type="hidden" value={propertyHunterName} />

          {/* Submit Button */}
          {isFormValid && (
            <button
              type="submit"
              disabled={loading}
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              {loading ? "Submitting..." : "Send Email"}
            </button>
          )}
        </form>
      </div>
    </AuthGuard>
  );
};

export default SendEmail;
